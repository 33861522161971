<script setup lang="ts"></script>
<template>
	<div class="skeleton-title">
		<ASkeleton class="title" />
		<ASkeleton class="actions" />
	</div>
</template>
<style lang="scss" scoped>
.skeleton-title {
	display: flex;
	justify-content: space-between;
	margin-bottom: 18px;
	height: 36px;

	.title {
		width: 344px;
	}

	.actions {
		width: 180px;
	}

	@include media-breakpoint-down(md) {
		margin-bottom: 8px;

		.title {
			max-width: 50%;
		}

		.actions {
			width: 100px;
		}
	}
}
</style>
